.notFound {
  @include verticalCenter;
  height: 95vh;
  max-width: 1200px;
  background: linear-gradient(115deg, $color-2 20%, transparent 100%);
  border-radius: $borderRadius;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
    font-size: 2rem;
  }
  a {
    padding: 1rem 2rem;
    border-radius: $borderRadius;
    background: $color-5;
    transition: .2s;

    &:hover {
      background: $color-4;
    }
  }
  h1 {
    padding: 3rem 4rem;
    margin-bottom: 3rem;
    font-size: 2rem;


  }
}